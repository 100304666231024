.LeftList {
  width: 400px;
  /* height: 100%; */
  top: 0;
  bottom: 100px;
  background: #f2f1ef;
  position: absolute;
  left: -400px;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 999;

  audio {
    position: absolute;
    width: 0;
    height: 0;
  }

  .Text {
    padding: 0 20px 0 20px;
  }

  .Headr {
    width: 100%;
    /* height: 74px; */
    position: relative;
    top: 0;
    left: 0;
    font-family: Raleway, sans-serif;

    background: #1f1f1f;
    /* height: 80px; */
    color: #fff;
    min-height: 70px;

    .BackBtn {
      position: absolute;
      top: 12px;
      left: 26px;
      font-weight: lighter;
      font-size: 1.0em;
      opacity: 0.4;
      cursor: pointer;
      overflow: hidden;
      /* height: 20px; */
      text-overflow: ellipsis;
      white-space: nowrap;

      a,a:visited,a:hover {
        color: #fff;
        text-decoration: none;
      }

      .backarrow {
        height: 20px;
        position: relative;
        bottom: -3px;
        display: inline-block;
        margin: 0 10px 0 0;

        svg {
          polygon {
            fill: #fff;
          }
        }
      }
    }

    .Title {
      padding: 46px 20px 10px 27px;
      font-weight: 100;
      font-size: 1.2em;
    }
  }
}

@media only screen and (max-width: 900px) {
  .LeftList {
    width: 100%;
    left: 0;
  }
}
