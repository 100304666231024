.SearchResult {
  position: absolute;
  top: 0;
  left: 0;
  /*background-color: #fe94a6;*/
  z-index: 99999;
  width: 65%;
  height: 100%;
  overflow-y: auto;
  background-color: rgba(0,0,0,0.2);
  /* top: 100%; */

  .ResultItem {
    cursor: pointer;
    padding: 5px 5px 5px 0;
    border-bottom: 2px solid #bfbfbf;
    height: 90px;
    box-sizing: border-box;
    background-color: #f0e7e7;

    a {
      display: grid;
      grid-template-columns: [image] 80px [text] auto [type] 80px;
      text-decoration: none;
      font-size: 16px;
      color: #000;

      .Image {
        height: 80px;
        width: 80px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .Text {
        /* line-height: 80px; */
        white-space: normal;
        /* margin: 0 10px; */
        padding: 0 10px;
        display: flex;
        align-items: center;
      }


      .Type {
        color: grey;
        font-size: 0.9em;
        /* float: left; */
        /* line-height: 80px; */
        /* margin: 0 5px 0 5px; */
        padding: 0 10px;
        display: flex;
        align-items: center;
      }
    }
  }

  .SearchInput {
    display: none;
  }
}
@media only screen and (max-width: 900px) {
  .SearchResult {
    width: 100%;
    background-color: #f2f1ef;
    .SearchInput {
      height: 100px;
      display: block;

      input {
        width: 90%;
        margin: 32px 5% 0 5%;
        font-size: 1.5em;
        box-sizing: border-box;
      }
    }

    .ResultItem {


      a {
        grid-template-rows: [row1] 50% [row2] 50%;
        grid-template-columns: [image] 80px [text] auto;

        .Image {
          grid-row-start: 1;
          grid-row-end: 3;
        }

        .Text,.Type {
          line-height: normal;
        }
      }
    }
  }
}

