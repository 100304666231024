.LanguageSelect {
  position: absolute;
  top: 74px;
  left: 0;
  right: 0;
  bottom: 0;

  background: #fff;
  padding: 25px 0 0 0;
  box-sizing: border-box;
  z-index: 9999;
  overflow: auto;

  .welcome {
    width: 100%;
    text-align: center;
    /* margin: 30px 0 0 0; */
    font-size: 1.4em;
    font-weight: 100;
    margin: 0 0 10px 0;
    /* text-decoration: underline; */
  }

  .langoption {
    width: 170px;
    margin: 0 auto;
    text-align: left;
    font-size: 1.4em;
    padding: 0 0 5px 0;
    font-weight: 100;
    cursor: pointer;

    a:link {
      text-decoration: none;
    }

    a:visited {
      text-decoration: none;
    }

    /* mouse over link */
    a:hover {
    }

    /* selected link */
    a:active {
      text-decoration: none;
    }
  }
}
