.Titlebar {
  background: -webkit-gradient(linear,left top,left bottom,from(#f99827),to(#fce67e));
  background: linear-gradient(180deg,#f99827,#fce67e);
  /*position: relative;*/
  background-size: cover;
  background-position: 100%;
  background-repeat: no-repeat;
  color: #fff;
  font-family: Raleway,sans-serif;
  background-image: url("/forest.png");

  height: 140px;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;

  .BackBtn {
    position: absolute;
    top: 12px;
    left: 26px;
    font-weight: lighter;
    font-size: 1.0em;
    opacity: 0.4;
    cursor: pointer;
    overflow: hidden;
    /* height: 20px; */
    text-overflow: ellipsis;
    white-space: nowrap;

    opacity: 0.7;

    .backarrow {
      height: 20px;
      position: relative;
      bottom: -3px;
      display: inline-block;
      margin: 0 10px 0 0;

      svg {
        polygon {
          fill: #fff;
        }
      }
    }

    a,a:visited,a:hover {
      color: #fff;
      text-decoration: none;
    }
  }

  .Title {
    position: absolute;
    top: 46px;
    left: 27px;
    font-weight: 100;
    font-size: 1.2em;
  }

  .Tabs  {
    position: absolute;
    bottom: 0;
    height: 38px;
    width: 100%;
  }

  .TabBtn {
    color: #000;
    float: left;
    padding: 10px;
    background: rgba(255,255,255,0.4);
    cursor: pointer;
    height: 19px;
  }
  .TabBtn.Active {
    background: #f2f1ef;
    cursor: default;
  }

}
