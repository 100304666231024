.Fullscreen {
  position: fixed;
  top: 5vh;
  left: 5vw;
  right: 5vw;
  bottom: 5vh;
  background-color: #1f1f1f;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1000;

  .tinyscreen {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 10px;
    right: 10px;

    path {
      fill: #bfbfbf !important;
    }
  }

  .caption {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(128, 128, 128,0.7);
    color: #fff;
    padding: 10px;
    box-sizing: border-box;
  }

  .previous,.next {
    position: absolute;
    top: 0;
    width: 25%;
    bottom: 0;
    background-color: rgba(0,0,0,0.1);
  }
  .previous svg,.next svg {
    height: 200px;
    position: absolute;
    top: 50%;
    transform: translate(0,-50%);
  }

  .previous {
    left: 0;
    svg {
      left: 0;
    }
  }
  .next {
    right: 0;
    svg {
      right: 0;
    }
  }

}

@media only screen and (max-width: 900px) {
  .Fullscreen {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    .previous,.next {
      height: 50px;
      top: auto;
      width: 50px;
      svg {
        height: 100%;
      }
    }
    .tinyscreen {
      bottom: 0;
      top: auto;
      right: 50%;
      transform: translate(50%);
    }
  }
}
