.SwapButton {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  /* right: 0; */
  bottom: 0;
  z-index: 1000;
  width: 30px;

  .gmapsIcon {
    position: relative;
    left: calc(100% - 23px);
    top: 9px;
    height: 17px;
  }

  .mapSwapBtnText {
    color: #000;
    position: absolute;
    top: 40px;
    left: calc(100% - 24px);
    z-index: 1;
    -webkit-transform: rotate(90deg);
    -webkit-transform-origin: bottom left;
    transform: rotate(90deg);
    transform-origin: bottom left;
  }
}
@media only screen
and (max-width: 900px) {
  .SwapButton {
  display: block;
  }
}

.SwapButtonGuides {
  background: #FA8228;
  .mapSwapBtnText {
    color: #fff;
  }
}

.SwapButtonMap {
  background: #d9d9d9;
}
