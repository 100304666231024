.LeftListMenu {
  width: 400px;
  bottom: 0;
  height: 100px;
  /* background: #8F8F8F; */
  background-color: #fff;
  color: #000;
  position: absolute;
  left: -400px;
  display: block;
  z-index: 999;

  .Gridcontainer {
    display: grid;
    /* grid-template-columns: 16.6% 16.6% auto auto 16.6% 16.6%; */
    grid-template-columns: 20% 20% 20% 20% 20%;
    grid-template-rows: 13px 15px 40px;
    grid-template-areas:
            "a b b b c"
            "e e e e e"
            "g h i j k";
    text-align: center;
    grid-row-gap: 1px;
    font-size: 11px;
    margin: 5px 0 0 0;

    .progressTime, .progressTotal {
      font-weight: 700;
    }
    .progressBar {
      grid-area: 1 / 2 / 1 / span 3;

      progress {
        width: 100%;
        height: 5px;
        margin: 0 0 3px 0;

        background-color: #ccd9dd;
      }
    }
    .progressClicker {
      grid-area: b/b/e/b;
      z-index: 2;
    }

    .title {
      grid-area: e / e / e / e;
      /* line-height: 11px; */
      padding: 0 20px 2px 20px;
      box-sizing: border-box;
      word-wrap: break-word;
      white-space: nowrap;
      text-overflow: ellipsis;
      /* width: 274px; */
      overflow: hidden;
    }

    .ico {
      height: 40px;
    }
    .ico.small {
      height: 24px;
      margin: 8px 0 0 0;
    }

    svg {
      height: 100%;
      path {
        fill: #ccd9dd;
      }
    }

    .deactivated {
      color: #525252;
    }
    svg.deactivated {
      path {
        fill: #525252;
      }
    }

  }
}
.LeftListMenu.Black {
  background-color: #1f1f1f;
  color: #ccd9dd;
}

@media only screen and (max-width: 900px) {
  .LeftListMenu {
    left: 0;
    width: 100%;
  }
}
