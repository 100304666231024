.ImagePlayer {
  width: 100%;
  margin: 1px 0 0 0;
  position: relative;
  background-color: #1f1f1f;

  .ScrollThingi {
    overflow: hidden;
    height: 50vh;

    .Images {
      height: 100%;
      width: 100%;
      position: relative;

      .Img {
        float: left;
        height: 100%;
        width: 100%;
        padding: 10px 0 10px 0;
        box-sizing: border-box;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-clip: content-box;
        position: absolute;
      }
    }
  }

  .dotNodes {
    position: absolute;
    left: 50%;
    bottom: 34px;
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
    padding: 5px;
    background: rgba(250,250,250,0.4);
    border-radius: 10px;
    display: none;

    .dotNode {
      border-radius: 50%;
      width: 10px;
      height: 10px;
      background: #000;
      float: left;
      margin: 3px;
    }
    .dotNode.current {
      background: #fff;
    }
  }
  .dotNodes.active {
    display: block;
  }

  .Right {
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translate(0,-50%);
    transform: translate(0,-50%);
    height: 64px;
  }
  .Left {
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translate(0,-50%);
    transform: translate(0,-50%);
    height: 64px;
  }

  .caption {
    color: #fff;
    background: rgba(255,255,255,0.5);
    /* position: absolute; */
    /* bottom: 0; */
    /* left: 0; */
    width: 100%;
    overflow: hidden;
    /* height: 18px; */
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 4px 20px 6px 20px;
    font-size: 0.9em;
    box-sizing: border-box;
  }

  @keyframes swoshLeft {
    /*from {left: 100%;}
    to {left: 0%;}*/
    from {transform: translate(100%);}
    to {transform: translate(0%);}
    from {-webkit-transform: translate(100%);}
    to {-webkit-transform: translate(0%);}
  }
  .swoshLeft {
    animation-name: swoshLeft;
    animation-duration: 1s;
  }
  @keyframes swoshRight {
    from {transform: translate(-100%);}
    to {transform: translate(0%);}
    from {-webkit-transform: translate(-100%);}
    to {-webkit-transform: translate(0%);}
  }
  .swoshRight {
    animation-name: swoshRight;
    animation-duration: 1s;
  }

  .fullscreen_button {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 40px;
  }

  svg {
    polygon {
      fill: #bfbfbf;
    }
  }


}
