.ListItem {
  position: relative;
  height: 80px;
  width: 100%;
  /* margin: 0 10px 0 10px; */
  cursor: pointer;
  box-sizing: content-box;
  /* border-bottom: 1px solid #e6e6e6; */
  /* padding: 10px 10px 10px 10px; */
  margin: 3px 0 0 3px;
  background: #fff;
  text-align: initial;
  a,a:visited,a:hover {
    color: inherit;
    text-decoration: none;
  }

  svg {
    path {
      fill: #000;
    }
  }


  .image {
    width: 100px;
    height: 80px;
    position: absolute;
    left: 0;
    top: 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .otherStuff {
    width: calc(100% - 100px);
    height: 100%;
    margin-left: 100px;
    position: relative;

    .title {
      position: absolute;
      top: 10px;
      left: 10px;
      width: calc(100% - 20px);
      line-height: 20px;
      font-size: 1em;
      overflow: hidden;
      height: 44px;
    }

    .subtitle {
      position: absolute;
      top: 54px;
      left: 15px;
      font-size: 0.9em;
      color: #b3b3b3;

      svg {
        height: 15px;
      }
    }
  }
}
.ListItem.marked {
  background: #404040;
  color: #e3e3e3;


}
